import React, { Component } from 'react';

export default class Build extends Component {
  render() {
    return (
        <div>
          <h2>Build</h2>
        </div>
    );
  }
}
