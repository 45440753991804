import React, { Component } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

export default class About extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.classes = props.classes;
    this.fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
  }

  render() {
    return (
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={this.fixedHeightPaper}>
            {/* <Chart /> */}
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={this.fixedHeightPaper}>
            {/* <Deposits /> */}
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={this.classes.paper}>
            {/* <Orders /> */}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
